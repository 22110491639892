// import "font-awesome";
import "../../scss/main";

import Alpine from "alpinejs";

import "bootstrap";
import "./modules";

window.Alpine = Alpine;

// Maybe provide more lifecycle events here?
// https://alpinejs.dev/essentials/lifecycle alpine:initializing, alpine:initialized
// then remove start and auto trigger?

export default {
  start: () => {
    Alpine.start();
  },
};
