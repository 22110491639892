import api from "./api";
import Alpine from "alpinejs";
Alpine.data("userAlertsApp", function () {
  const userAlerts = async function () {
    return await api.get("/auth/eventhoster/alerts/user-alerts");
  };

  return {
    alerts: [],
    init: function () {
      userAlerts().then((response) => {
        const data = response.data;

        if (data && data.length > 0) {
          this.alerts = data;
        }
      });
    },
  };
});
