import app from "../app";
import ready from "../app/modules/ready";
import ScrollReveal from "scrollreveal";

ready(() => {
  let scrollReveal = ScrollReveal({
    duration: 800,
  });

  scrollReveal.reveal(".tickets", {
    distance: "30%",
    duration: 800,
    rotate: {
      z: 20,
    },
    viewFactor: 0.4,
    reset: true,
  });

  scrollReveal.reveal(".bracket-one", {
    delay: 150,
    duration: 450,
    scale: 0.7,
    reset: true,
  });

  scrollReveal.reveal(".bracket-two", {
    delay: 700,
    duration: 450,
    scale: 0.7,
    reset: true,
  });
});
