export const getCsrfHeaders = () => {
  let token = document.querySelector('meta[name="t"]').getAttribute("content");

  return {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-TOKEN": token,
  };
};

export const csrfHeaders = getCsrfHeaders;
